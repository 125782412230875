import React from 'react';
import { formatWhatsAppText } from '../../utils/formatWhatsAppText';

const ChatContext = ({ context }) => {


    const handleClick = () => {
        const element = document.querySelector(`._${context.messageId}`);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
            element.classList.add('highlight');
            setTimeout(() => {
                element.classList.remove('highlight');
            }, 2000);
        }
    };

    if (typeof context === 'undefined') {
        return null;
    }

    switch (context.type) {
        case 'text':
            return (<div className="message__context bubble" onClick={() => handleClick()}><p dangerouslySetInnerHTML={{ __html: formatWhatsAppText(context.text.slice(0, 99) + "...", '', true) }}></p></div>);
        default:
            return (<div className="message__context bubble" onClick={() => handleClick()}><p dangerouslySetInnerHTML={{ __html: formatWhatsAppText(context.text.slice(0, 99) + "...", '', true) }}></p></div>);
    }
};


export default ChatContext;