import React, { useState } from 'react';
import { Lightbox } from "react-modal-image";
import { ReactComponent as LoaderIcon } from './icons/loader.svg';
import { ReactComponent as PdfIcon } from './icons/pdf.svg';
import { ReactComponent as CsvIcon } from './icons/csv.svg';
import { ReactComponent as DocIcon } from './icons/doc.svg';
import { ReactComponent as XlsIcon } from './icons/xls.svg';
import { ReactComponent as TxtIcon } from './icons/txt.svg';

const s3BucketLink = process.env.REACT_APP_S3_BUCKET_LINK;

const ChatImage = (props) => {
    let src = props.src;
    const [lightboxStatus, setLightboxStatus] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);
    const hideLoader = () => {
        setImageLoaded(true);
        props.onImageLoad();
    };
    const closeLightbox = () => {
        setLightboxStatus(false);
    };
    const openLightbox = () => {
        setLightboxStatus(true);
    };
    if (src) {
        src = src.includes('https://') ? src : s3BucketLink + '/' + src;
        const url = new URL(src);
        const pathName = url.pathname;
        const parts = pathName.split("/");
        let fileName = decodeURIComponent(parts[parts.length - 1]);
        const extParts = fileName.split(".");
        const extension = extParts[extParts.length - 1];
        if (props.filename) {
            fileName = props.filename + '.' + extension;
        }
        let placeholderImage = null;
        if (props.contentType === 'image' || props.contentType === "buttons") {
            return (
                <>
                    {!imageLoaded &&
                        <div className='loader_content'>
                            <LoaderIcon />
                        </div>
                    }
                    <img
                        className='content__image'
                        style={{
                            cursor: "pointer",
                            maxWidth: "100%",
                            maxHeight: "100%",
                            display: imageLoaded ? 'block' : 'none'
                        }}
                        src={src}
                        alt={fileName}
                        onClick={openLightbox}
                        onLoad={hideLoader}
                    />
                    {lightboxStatus && (
                        <Lightbox
                            className='content__image'
                            medium={src}
                            large={src}
                            alt={fileName}
                            onClose={closeLightbox}
                            hideDownload={true}
                            imageBackgroundColor={"unset"}
                        />
                    )}
                </>
            );
        } else if (props.contentType === 'video') {
            return (
                <video controls src={src}></video>
            );
        } else if (props.contentType === 'document') {
            switch (extension) {
                case "pdf":
                    placeholderImage = <PdfIcon className="uploaded_media_type" />;
                    break;
                case "csv":
                    placeholderImage = <CsvIcon className="uploaded_media_type" />;
                    break;
                case "doc":
                case "docx":
                    placeholderImage = <DocIcon className="uploaded_media_type" />;
                    break;
                case "xls":
                case "xlsx":
                    placeholderImage = <XlsIcon className="uploaded_media_type" />;
                    break;
                case "txt":
                    placeholderImage = <TxtIcon className="uploaded_media_type" />;
                    break;
                default:
                    return;
            }
            return (
                <a href={src} target='_blank' rel="noreferrer noopener" className="message__preview_link" download>
                    <div className="message__context message__preview">
                        <div className='placeholder_image'>
                            {placeholderImage}
                        </div>
                        <p>{fileName}</p>
                    </div>
                </a>
            );
        }
    }
    return '';
};

export default ChatImage;