import React from 'react';

const CreateQrMsg = ({ data, socket, userData }) => {
  const handleClick = (message, payload, messageData) => {
    let contextData = {
      media_url: messageData.media_url,
      messageId: messageData.id,
      text: messageData.text,
      type: messageData.content_type
    }

    if (String(message).trim()) {
      socket.emit('message', {
        text: message,
        phone: userData.phone,
        from: "user",
        mid: userData.mid,
        id: `${socket.id}${Math.random()}`,
        socketID: socket.id,
        timestamp: Math.floor(Date.now() / 1000),
        context: contextData,
        payload: payload,
        messageId: messageData.id,
      });
    }
  };

  let html = ``;
  let buttons = data.buttons;

  if (typeof buttons !== 'undefined' && buttons !== '') {
    if ("has_buttons_disabled" in data && data.has_buttons_disabled === true) {
      html = buttons.map((button, index) =>
        <div className='quick_reply_button quick_reply_button_disabled' key={index}>{button.reply.title}</div>
      );
    } else {
      html = buttons.map((button, index) =>
        <div className='quick_reply_button' onClick={() => handleClick(button.reply.title, button.reply.payload, data)} key={index}>{button.reply.title}</div>
      );
    }
  }

  return html;
};


export default CreateQrMsg;