import socketIO from 'socket.io-client';
import ChatPage from './components/ChatPage';
import { useEffect, useState } from 'react';
import axios from 'axios';
function App() {

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [token, setToken] = useState(null);
  const [inappConfig, setInappConfig] = useState(null);

  const authenticateChat = async (urlData) => {

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/api/chat/token`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(urlData)
    };

    let response = await axios(config);
    if (response.data.accessToken) {
      urlData.accessToken = response.data.accessToken;
      setIsAuthenticated(true);
      setUserData(urlData);
      setToken(response.data.accessToken);
      setInappConfig(response.data.inapp_config ?? null);
    }
    else {
      setIsAuthenticated(false);
      setUserData(null);
    }
  }
  useEffect(() => {
    let queryParams = new URLSearchParams(window.location.search.split("?")[1]);
    let urlData = {
      "phone": queryParams.get("phone"),
      "mid": queryParams.get("mid"),
      "fcm_token": queryParams.get("fcm_token")
    }
    authenticateChat(urlData);
    //login api with jwt    
    setIsAuthenticated(false);
  }, []);

  if (isAuthenticated) {
    const socket = socketIO.connect(`${process.env.REACT_APP_SOCKET_URL}`, { auth: { token } });
    let roomId = userData.mid + "." + userData.phone;
    socket.emit("join_room", { roomId });
    return (
      <div>
        <ChatPage socket={socket} userData={userData} inappConfig={inappConfig} />
      </div>
    );
  } else {
    // return <div>Permission Denied</div>
  }
}

export default App;
