function formatWhatsAppText(text = null,caption = null,context = false) {
    if(text === null || text === ''){
        if(caption !== null && caption !== ''){
            text = caption
        }
    }
    
    if(text === null) return null;

    const boldRegex = /\*([^\*]+)\*/g;
    const italicRegex = /_([^_]+)_/g;
    const strikethroughRegex = /~([^~]+)~/g;
    const monospaceRegex = /`([^`]+)`/g;

    const boldText = String(text).replace(boldRegex, "<b>$1</b>");
    const italicText = boldText.replace(italicRegex, "<i>$1</i>");
    const strikethroughText = italicText.replace(strikethroughRegex, "<s>$1</s>");
    const monospaceText = strikethroughText.replace(monospaceRegex, "<code>$1</code>");
    var linebreakText = monospaceText.replace(/\n/g, " <br>");

    return convertToLink(linebreakText,context);

}

function formatTimestamp(timestamp) {
    const date = new Date(timestamp * 1000);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return formattedDate + " " + formattedTime;
}

function convertToLink(text,context) {
    if(context){
      return text
    }
    
    const urlRegex = /((?:https?:\/\/|ftp:\/\/|www\.)[^\s]+)/g;
    return text.replace(urlRegex, (url) => {
      if (url.startsWith("www.")) {
        return `<a href="https://${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      } else {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      }
    });
}

module.exports = {
    formatWhatsAppText, 
    formatTimestamp
}