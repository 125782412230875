import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import FormData from 'form-data';
import Modal from 'react-modal';
import StatusAlert, { StatusAlertService } from 'react-status-alert';
import 'react-status-alert/dist/status-alert.css';
import pdf_icon from './icons/pdf.svg';
import csv_icon from './icons/csv.svg';
import doc_icon from './icons/doc.svg';
import xls_icon from './icons/xls.svg';
import txt_icon from './icons/txt.svg';

const previewModalStyles = {
  content: {
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    border: '0px',
    padding: '0px 0px 0px 0px',
    borderRadius: '0px',
    backgroundColor: 'rgba(0, 0, 0)'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000
  },
};

Modal.setAppElement('#root');

const ChatFooter = ({ socket, userData }) => {
  const [message, setMessage] = useState('');
  const [uploadFile, setUploadFile] = useState(null);
  const [file, setSelectedFile] = useState(null);
  const [fileSize, setSelectedFileSize] = useState(null);
  const [fileType, setSelectedFileType] = useState(null);
  const [fileName, setSelectedFileName] = useState(null);
  const [fullFileName, setSelectedFullFileName] = useState(null);
  const [placeholderImage, setPlaceholderImage] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const fileInputRef = useRef(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const showInfoAlert = async (message) => {
    StatusAlertService.showInfo(message, {
      withCloseIcon: false
    });
  }

  const handleSendMessage = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    closeModal();
    let uploaded_media = null;
    if (uploadFile) {
      uploaded_media = await uploadFileAndGetUrl(uploadFile);
      if (!uploaded_media) {
        showInfoAlert('Enable to upload, please try again later.');
      }
    }
    if (String(message).trim() || uploaded_media) {
      let data = {
        text: message,
        phone: userData.phone,
        from: "user",
        mid: userData.mid,
        id: `${socket.id}${Math.random()}`,
        socketID: socket.id,
        timestamp: Math.floor(Date.now() / 1000)
      };
      if (uploaded_media) {
        data.media_url = uploaded_media.url;
        data.content_type = (fileType === "image" || fileType === "video") ? fileType : "document";
        data.full_filename = fullFileName;
      } else {
        data.content_type = 'text';
      }
      socket.emit('message', data);
    }
    setMessage('');
    setUploadFile(null);
    setSelectedFile(null);
    setSelectedFileType(null);
    setSelectedFileName(null);
    setSelectedFullFileName(null);
    setSelectedFileSize(null);
    setPlaceholderImage(null);
    setButtonDisabled(false);
  };

  const uploadFileAndGetUrl = async (file) => {
    let data = new FormData();
    data.append('file', file);
    let config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/api/chat/getS3Url`,
      headers: { 'content-type': 'multipart/form-data' },
      data: data
    };
    try {
      let response = await axios(config);
      return response.data;
    } catch (err) {
      return null;
    }
  }

  function bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return ''
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
    if (i === 0) return `${bytes} ${sizes[i]})`
    return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
  }

  const handleFileChange = (event) => {
    const reader = new FileReader();
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const selectedFileName = event.target.files[0].name;
      setSelectedFullFileName(selectedFileName);
      setSelectedFileSize(bytesToSize(selectedFile.size));
      const fileSizeKiloBytes = selectedFile.size / 1024
      if (fileSizeKiloBytes > 16384) {
        showInfoAlert('File size should be less than 16mb');
        fileInputRef.current.value = null;
        return;
      }
      const allowedImageTypes = ["image/avif", "image/bmp", "image/gif", "image/jpeg", "image/png", "image/webp", "image/tiff"];
      const allowedVideoTypes = ["video/webm", "video/x-msvideo", "video/mp4", "video/mpeg", "video/ogg", "video/3gpp"];
      if (allowedImageTypes.includes(selectedFile.type)) {
        setSelectedFileType("image");
        setPlaceholderImage(null);
      } else if (allowedVideoTypes.includes(selectedFile.type)) {
        setSelectedFileType("video");
        setPlaceholderImage(null);
      } else if (selectedFile.type.includes("application/pdf")) {
        setSelectedFileType("pdf");
        setPlaceholderImage(pdf_icon);
      } else if (selectedFile.type.includes("text/csv")) {
        setSelectedFileType("csv");
        setPlaceholderImage(csv_icon);
      } else if (["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(selectedFile.type)) {
        setSelectedFileType("doc");
        setPlaceholderImage(doc_icon);
      } else if (["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"].includes(selectedFile.type)) {
        setSelectedFileType("xls");
        setPlaceholderImage(xls_icon);
      } else if (selectedFile.type.includes("text/plain")) {
        setSelectedFileType("txt");
        setPlaceholderImage(txt_icon);
      } else {
        showInfoAlert('Please select an image, video or PDF');
        fileInputRef.current.value = null;
        return;
      }
      openModal();
      setUploadFile(selectedFile);
      reader.readAsDataURL(selectedFile);
      setSelectedFileName(selectedFileName.substring(0, selectedFileName.lastIndexOf('.')));
    }
    reader.onload = (readerEvent) => {
      setSelectedFile(readerEvent.target.result);
    };
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleKeyDown = (event) => {
    if (!file && event.key === "Enter") {
      handleSendMessage(event);
    }
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    fileInputRef.current.value = null;
  }

  return (
    <div className="chat__footer">
      <form className="form" onSubmit={handleSendMessage} encType="multipart/form-data">
        <input
          ref={fileInputRef}
          type="file"
          accept="image/avif, image/bmp, image/gif, image/jpeg, image/png, image/webp, image/tiff, video/webm, video/x-msvideo, video/mp4, video/mpeg, video/ogg, video/3gpp, application/pdf, text/csv, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain"
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
        <textarea
          type="text"
          placeholder="Write a message"
          className="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <button type="button" onClick={handleButtonClick} className="sendBtn">
          <FontAwesomeIcon icon={faPaperclip} />
        </button>
        <button className="sendBtn" id="sendBtn">
          <FontAwesomeIcon icon={faPaperPlane} />
        </button>
      </form>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={previewModalStyles}
        contentLabel="Preview"
        shouldCloseOnOverlayClick={false}
      >
        <div className='preview_modal_container'>
          <div className='preview_modal_header'>
            <p>{fileName}</p>
          </div>
          <div className={`preview_modal_media_container ${(fileType === "image" || fileType === "video") ? "preview_black_background" : ""}`}>
            <div className='preview_modal_media'>
              {placeholderImage !== null && file !== null &&
                <>
                  <img src={placeholderImage} alt={fileType} style={{ width: 90 }} />
                  <div className='preview_modal_media_meta'>
                    <p className='preview_modal_media_meta_file'>{fullFileName}</p>
                    <p className='preview_modal_media_meta_size'>{fileSize}</p>
                  </div>
                </>
              }
              {fileType === "image" && file !== null && <img src={file} alt="" />}
              {fileType === "video" && file !== null && <video controls src={file}></video>}
            </div>
          </div>
          <div className="preview_modal_buttons">
            <button onClick={closeModal} disabled={buttonDisabled} className="preview_modal_button preview_modal_cancel_button">Cancel Upload</button>
            <button onClick={handleSendMessage} disabled={buttonDisabled} className="preview_modal_button preview_modal_send_button"><FontAwesomeIcon icon={faPaperPlane} /></button>
          </div>
        </div>
      </Modal>
      <StatusAlert />
    </div>
  );
};

export default ChatFooter;