import React from 'react';
import { formatWhatsAppText, formatTimestamp } from '../../utils/formatWhatsAppText';
import ChatImage from './ChatImage';
import ChatContext from './ChatContext';
import CreateQrMsg from './CreateQrMsg';
import CreateListMsg from './CreateListMsg';

const ChatMessage = ({ message, socket, userData, onImageLoad }) => {
    if (message.from === "user") {
        return <>
            <div className={`message__chats _${message.id}`}>
                <p className="sender__name">You</p>
                <div className={`message__sender bubble`}>
                    <ChatContext context={message.context}></ChatContext>
                    <ChatImage src={message?.media_url} contentType={message.content_type} onImageLoad={onImageLoad}></ChatImage>
                    {(message.text || message.caption) && (
                        <p className='message__content' dangerouslySetInnerHTML={{ __html: formatWhatsAppText(message.text, message.caption) }}></p>
                    )}
                    <p className='timestamp'>{formatTimestamp(message.timestamp)}</p>
                </div>
            </div>
        </>
    } else if (message.type === "alert") {
        if (message.inapp_chat_alert !== null && message.inapp_chat_alert !== undefined && message.inapp_chat_alert !== '') {
            return <>
                <div className='message__alert'>
                    <div className='alert_content'>
                        <p className='alert_text' dangerouslySetInnerHTML={{ __html: formatWhatsAppText(message.inapp_chat_alert) }}></p>
                    </div>
                </div>
            </>
        } else if (message.isBroadcasted && message.text !== null && message.text !== undefined && message.text !== '') {
            return <>
                <div className='message__alert'>
                    <div className='alert_content'>
                        <p className='alert_text' dangerouslySetInnerHTML={{ __html: formatWhatsAppText(message.text) }}></p>
                    </div>
                </div>
            </>
        }
    } else {
        return <>
            <div className={`message__chats _${message.id}`}>
                <p className='agent__placeholder__value'>Agent</p>
                <div className={`message__recipient bubble ${("has_buttons_disabled" in message && message.has_buttons_disabled === true) ? "message__recipient_disabled" : ""}`}>
                    <ChatImage filename={message?.filename} src={message?.media_url} contentType={message.content_type} onImageLoad={onImageLoad}></ChatImage>
                    {(message.text || message.caption) && (
                        <p className='message__content' dangerouslySetInnerHTML={{ __html: formatWhatsAppText(message.text, message.caption) }}></p>
                    )}
                    <CreateListMsg socket={socket} userData={userData} data={message}></CreateListMsg>
                    <CreateQrMsg socket={socket} userData={userData} data={message}></CreateQrMsg>
                    <p className='timestamp'>{formatTimestamp(message.timestamp)}</p>
                </div>
            </div>
        </>
    }
};

export default ChatMessage;
