import React, { useEffect, useState, useRef } from 'react';
import ChatBody from './chat/ChatBody';
import ChatFooter from './chat/ChatFooter';
import axios from 'axios';
import Theme from './chat/Theme';
import { showFooter, hideFooter, removeTypingIndicator, addTypingIndicator } from '../utils/showHideFooter';

const ChatPage = ({ socket, userData, inappConfig }) => {

  const [theme, setTheme] = useState({
    text_color: '#ffffff',
    bg_color: '#000000',
    link_color: '#6495ed',
    incoming_bubble_color: '#262d31',
    incoming_text_color: '#ffffff',
    incoming_disabled_bubble_color: '#262d31',
    incoming_disabled_text_color: '#ffffff',
    outgoing_bubble_color: '#056162',
    outgoing_text_color: '#ffffff',
    quickreplies_bg_color: '#ffffff',
    quickreplies_text_color: '#000000',
    quickreplies_border_color: '#ffffff',
    quickreplies_disabled_bg_color: '#000000',
    quickreplies_disabled_text_color: '#ffffff',
    quickreplies_disabled_border_color: '#000000',
    chatfooter_button_color: '#ffffff',
    chatfooter_button_icon_color: '#000000',
    chatfooter_textarea_bg_color: '#33383b',
    chatfooter_textarea_color: '#ffffff',
    chatfooter_bg_color: '#33383b',
    header_bg_color: '#33383b',
    header_text_color: '#ffffff',
    scrolldown_bg_color: '#1a1b20',
    scrolldown_icon_color: '#ffffff',
    context_bg_color: '#1a1b20',
    context_color: '#ffffff',
    highlight_bubble_bg: '#396984',
    typing_icon_color: '#ffffff',
    chatalert_bg_color: '#1a1b20',
    chatalert_text_color: '#eee7e7',
    chatclosed_bg_color: '#1a1b20',
    chatclosed_text_color: '#ffffff',
    chatclosed_link_bg_color: '#1a1b20',
    chatclosed_link_text_color: '#ffffff',
  });

  const [chatmessages, setChatMessages] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [showChatInput, setShowChatInput] = useState(false);
  const [showChatClosedButton, setShowChatClosedButton] = useState(false);

  let showCloseButton = false;
  if (inappConfig && ("config" in inappConfig) && ("show_close_button" in inappConfig.config) && (inappConfig.config.show_close_button == "true")) {
    showCloseButton = true;
  }

  const getOldMessages = async (limit, offset) => {
    let urlData = {
      "user_id": userData.mid,
      "phone": userData.phone,
      "limit": limit,
      "offset": offset
    };
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/api/chat/getOldMessages`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(urlData)
    };

    let oldChatData = await axios(config);
    oldChatData = oldChatData.data;
    if (inappConfig && ("config" in inappConfig) && ("disable_inapp_textbox" in inappConfig.config) && (inappConfig.config.disable_inapp_textbox == "true")) {
      if ("show_chat_input" in oldChatData.chat_info) {
        setShowChatInput(oldChatData.chat_info.show_chat_input);
      } else {
        setShowChatInput(true);
      }
    } else {
      setShowChatInput(true);
    }
    let chatsArray = oldChatData.chats;
    let oldChatsArray = [];
    let lastChatTimestamp = 0;
    let currentTimestamp = Math.floor(Date.now() / 1000);
    let chatReinitiateTimer = 3600;
    if (chatsArray !== undefined)
      chatsArray.forEach(chat => {
        if (chat.channel === "inappchat") {
          let chatData = {
            id: chat._id,
            text: chat.text_value ? chat.text_value : chat.text,
            from: chat.type === "incoming" ? "user" : "admin",
            content_type: chat.content_type,
            timestamp: chat.timestamp,
            media_url: chat.media_url,
            list_values: chat.list_values,
            buttons: chat.buttons,
            context: chat.context,
            caption: chat.caption,
            type: chat.type,
            inapp_chat_alert: chat.inapp_chat_alert,
            filename: chat.filename ?? null,
          }
          if (inappConfig && ("config" in inappConfig) && ("disable_question_buttons" in inappConfig.config) && (inappConfig.config.disable_question_buttons === "true")) {
            chatData['has_buttons_disabled'] = chat.has_buttons_disabled;
          }
          oldChatsArray.push(chatData);
        }
        if (lastChatTimestamp < chat.timestamp) {
          lastChatTimestamp = chat.timestamp;
        }
      });
    // setOffset((prevOffset) => prevOffset + oldChatsArray.length);
    setChatMessages(oldChatsArray.reverse());
    if (inappConfig && ("config" in inappConfig) && ("chat_reinitiate_timer" in inappConfig.config) && (inappConfig.config.chat_reinitiate_timer)) {
      chatReinitiateTimer = parseInt(inappConfig.config.chat_reinitiate_timer) * 60;
    }
    if (oldChatData.chat_info.ticket_status === 'closed') {
      setShowChatClosedButton(true);
    }
    if (oldChatsArray.length === 0 || (lastChatTimestamp !== 0 && (currentTimestamp - lastChatTimestamp) >= chatReinitiateTimer)) {
      let data = {
        text: "hi",
        phone: userData.phone,
        from: "user",
        mid: userData.mid,
        id: `${socket.id}${Math.random()}`,
        socketID: socket.id,
        timestamp: Math.floor(Date.now() / 1000),
        content_type: "text",
        new_user: true
      };
      socket.emit('message', data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getOldMessages(100, offset);
    // eslint-disable-next-line
    let inappValues = {}
    if (inappConfig !== null) {
      inappValues = {
        text_color: inappConfig.css.text_color ?? '#ffffff',
        bg_color: inappConfig.css.bg_color ?? '#000000',
        link_color: inappConfig.css.link_color ?? '#6495ed',
        incoming_bubble_color: inappConfig.css.incoming_bubble_color ?? '#262d31',
        incoming_text_color: inappConfig.css.incoming_text_color ?? '#ffffff',
        incoming_disabled_bubble_color: inappConfig.css.incoming_disabled_bubble_color ?? '#262d31',
        incoming_disabled_text_color: inappConfig.css.incoming_disabled_text_color ?? '#ffffff',
        outgoing_bubble_color: inappConfig.css.outgoing_bubble_color ?? '#056162',
        outgoing_text_color: inappConfig.css.outgoing_text_color ?? '#ffffff',
        quickreplies_bg_color: inappConfig.css.quickreplies_bg_color ?? '#ffffff',
        quickreplies_text_color: inappConfig.css.quickreplies_text_color ?? '#000000',
        quickreplies_border_color: inappConfig.css.quickreplies_border_color ?? '#ffffff',
        quickreplies_disabled_bg_color: inappConfig.css.quickreplies_disabled_bg_color ?? '#000000',
        quickreplies_disabled_text_color: inappConfig.css.quickreplies_disabled_text_color ?? '#ffffff',
        quickreplies_disabled_border_color: inappConfig.css.quickreplies_disabled_border_color ?? '#000000',
        chatfooter_button_color: inappConfig.css.chatfooter_button_color ?? '#000000',
        chatfooter_button_icon_color: inappConfig.css.chatfooter_button_icon_color ?? '#ffffff',
        chatfooter_textarea_bg_color: inappConfig.css.chatfooter_textarea_bg_color ?? '#33383b',
        chatfooter_textarea_color: inappConfig.css.chatfooter_textarea_color ?? '#ffffff',
        chatfooter_bg_color: inappConfig.css.chatfooter_bg_color ?? '#33383b',
        header_bg_color: inappConfig.css.header_bg_color ?? '#33383b',
        header_text_color: inappConfig.css.header_text_color ?? '#ffffff',
        scrolldown_bg_color: inappConfig.css.scrolldown_bg_color ?? '#1a1b20',
        scrolldown_icon_color: inappConfig.css.scrolldown_icon_color ?? '#ffffff',
        context_bg_color: inappConfig.css.context_bg_color ?? '#1a1b20',
        context_color: inappConfig.css.context_color ?? '#ffffff',
        highlight_bubble_bg: inappConfig.css.highlight_bubble_bg ?? '#396984',
        typing_icon_color: inappConfig.css.typing_icon_color ?? '#ffffff',
        chatalert_bg_color: inappConfig.css.chatalert_bg_color ?? '#1a1b20',
        chatalert_text_color: inappConfig.css.chatalert_text_color ?? '#eee7e7',
        chatclosed_bg_color: inappConfig.css.chatclosed_bg_color ?? '#1a1b20',
        chatclosed_text_color: inappConfig.css.chatclosed_text_color ?? '#ffffff',
        chatclosed_link_bg_color: inappConfig.css.chatclosed_link_bg_color ?? '#1a1b20',
        chatclosed_link_text_color: inappConfig.css.chatclosed_link_text_color ?? '#ffffff',
      };
      setTheme(inappValues);
    }
  }, []);

  useEffect(() => {
    const socketReconnect = () => {
      setTimeout(() => {
        if (socket.connected === false) {
          socket.open((err) => {
            if (err) {
              socketReconnect();
            }
          });
        }
      }, 3000);
    }
    socket.on("connect", () => {
      let roomId = userData.mid + "." + userData.phone;
      socket.emit("join_room", { roomId });
    });
    socket.on("disconnect", () => {
      socketReconnect();
    });
    socket.on('message', (data) => {
      if (data.new_user) {
        setShowChatClosedButton(false);
        return;
      }
      if (inappConfig && ("config" in inappConfig) && ("disable_question_buttons" in inappConfig.config) && (inappConfig.config.disable_question_buttons === "true")) {
        if ("context" in data) {
          for (const key in chatmessages) {
            if (chatmessages.hasOwnProperty(key)) {
              const messageArr = chatmessages[key];
              if (messageArr['id'] === data.context.messageId) {
                messageArr["has_buttons_disabled"] = true;
                break
              }
            }
          }
        }
      }
      data.timestamp = Math.floor(Date.now() / 1000);
      if (data.type === "alert") {
        data.isBroadcasted = true;
      }
      if (data.text === 'Thanks for the review' && data.message_source === 'bot_message') {
        setShowChatClosedButton(true);
      }
      setChatMessages([...chatmessages, data]);
      if (data.from !== 'user') {
        removeTypingIndicator();
      } else {
        setShowChatClosedButton(false);
        addTypingIndicator();
      }
      if (inappConfig && ("config" in inappConfig) && ("disable_inapp_textbox" in inappConfig.config) && (inappConfig.config.disable_inapp_textbox == "true")) {
        if (data.type !== "alert" && data.from !== 'user') {
          if (data.showChatInput) {
            showFooter();
            setShowChatInput(true);
          } else {
            hideFooter();
            setShowChatInput(false);
          }
        }
      }
    });
  }, [socket, chatmessages]);

  const reinitiateChat = () => {
    let data = {
      text: (inappConfig && inappConfig.text && inappConfig.text.reinitiate_message) ?? 'hi',
      phone: userData.phone,
      from: "user",
      mid: userData.mid,
      id: `${socket.id}${Math.random()}`,
      socketID: socket.id,
      timestamp: Math.floor(Date.now() / 1000),
      content_type: "text",
    };
    socket.emit('message', data);
  };

  return (
    <div>
      <Theme theme={theme} />
      <div className="chat__main">
        {isLoading ? ''
          : <ChatBody chatMessages={chatmessages} socket={socket} userData={userData} chatTitle={(inappConfig && inappConfig.text.title) ?? 'Support Chat'} showChatInput={showChatInput} showCloseButton={showCloseButton} />}
        {showChatClosedButton && (
          <div className={`message_sticky_alert ${showChatInput ? 'sticky_alert_has_footer' : ''}`}>
            <p className='alert_text'>{(inappConfig && inappConfig.text && inappConfig.text.chat_closed_text) ?? 'Still having an issue?'} <span onClick={reinitiateChat}>{(inappConfig && inappConfig.text && inappConfig.text.chat_closed_link_text) ?? 'Chat with us'}</span> </p>
          </div>
        )}
        {isLoading ? ''
          : <ChatFooter socket={socket} userData={userData} />}
      </div>
    </div>
  );
};

export default ChatPage;
