import React from 'react';

const CreateListMsg = ({ data, socket, userData }) => {
  const handleClick = (message, payload, messageData) => {
    let contextData = {
      media_url: messageData.media_url,
      messageId: messageData.id,
      in_app_message_id: messageData.in_app_message_id,
      text: messageData.text,
      type: messageData.content_type
    }
    if (String(message).trim()) {
      let new_message_id = Math.random();
      socket.emit('message', {
        text: message,
        phone: userData.phone,
        from: "user",
        mid: userData.mid,
        id: new_message_id,
        socketID: socket.id,
        timestamp: Math.floor(Date.now() / 1000),
        messageId: new_message_id,
        context: contextData,
        payload: payload,
      });
    }
  };

  let html = ``;
  let list_values = data.list_values;

  if (typeof list_values !== 'undefined' && list_values !== '') {
    if ("has_buttons_disabled" in data && data.has_buttons_disabled === true) {
      html = list_values.sections[0].rows.map((list, index) =>
        <div className='quick_reply_button quick_reply_button_disabled' key={index}>
          <p>{list.title}</p>
          <p className='list__description'>{list.description}</p>
        </div>
      );
    } else {
      html = list_values.sections[0].rows.map((list, index) =>
        <div className='quick_reply_button' onClick={() => handleClick(list.title, list.payload, data)} key={index}>
          <p>{list.title}</p>
          <p className='list__description'>{list.description}</p>
        </div>
      );
    }
  }

  return html;
};


export default CreateListMsg;