function hideFooter() {
    const element1 = document.querySelector('.message__container');
    element1.style.height = '93vh';

    const element2 = document.querySelector('.chat__footer');
    element2.style.display = 'none';
}

function showFooter(height = '78vh') {
    const element1 = document.querySelector('.message__container');
    element1.style.height = height;

    const element2 = document.querySelector('.chat__footer');
    element2.style.display = 'block';
}

function hideAgentName(){
    const agent__placeholder__value = document.querySelector('.agent__placeholder__value');
    agent__placeholder__value.style.display = 'none';
}

function showAgentName(){
    const agent__placeholder__value = document.querySelector('.agent__placeholder__value');
    agent__placeholder__value.style.display = 'block';
}

function removeTypingIndicator() {
    //console.log('removed')
    const typingIndicator = document.querySelector('.typing-indicator');
    if (typingIndicator) {
      typingIndicator.remove();
    }
}

function addTypingIndicator() {
    const messageContainer = document.querySelector('.message__container');
    const typingIndicatorCheck = document.querySelector('.typing-indicator');
    if (messageContainer && !typingIndicatorCheck) {
        //console.log('added')
        const element = document.createElement('div');
        element.className = 'message__chats typing-indicator';
        element.innerHTML = `
            <div class="message__recipient bubble dotbubble">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            </div>
        `;
        messageContainer.appendChild(element);
    }
}

module.exports = {
    hideFooter, 
    showFooter,
    hideAgentName,
    showAgentName,
    removeTypingIndicator,
    addTypingIndicator
}