import React, { useEffect, useState, useRef } from 'react';
import ChatMessage from './ChatMessage';
import { isMobile } from 'react-device-detect';
import { showFooter, hideFooter } from '../../utils/showHideFooter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faXmark } from '@fortawesome/free-solid-svg-icons';

const ChatBody = ({ chatMessages, socket, userData, chatTitle, showChatInput, showCloseButton }) => {
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [loadedImages, setLoadedImages] = useState(0);
  const lastMessageRef = useRef(null);
  const scrollableDivRef = useRef(null);
  let messagesWithImages = 0;
  let messagesToRender = [];

  useEffect(() => {
    const scrollableDiv = scrollableDivRef.current;
    const handleScroll = () => {
      if (scrollableDiv.scrollTop > 0) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };
    scrollableDiv.addEventListener('scroll', handleScroll);
    return () => {
      scrollableDiv.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (userData.mid === '6322b067bfceeba8330fbc7e' || userData.mid === '648ae8f9c53f48ec4a0eb1fb') {
      import('../css/DurexStyles.css');
      const chatHeader = document.querySelector('.chat__mainHeader');
      chatHeader.style.height = '9vh';
    } else if (userData.mid === '64b906db42c738e3cb069d30') {
      import('../css/CloviaStyles.css');
    }
    if (showChatInput === true) {
      if (userData.mid === '6322b067bfceeba8330fbc7e' || userData.mid === '648ae8f9c53f48ec4a0eb1fb') {
        showFooter('76vh');
      } else {
        showFooter();
      }
    } else {
      hideFooter();
    }
  }, []);

  const scrollDown = (event) => {
    event.preventDefault();
    let objDiv = document.getElementById("msg__container");
    objDiv.scrollTop = objDiv.scrollHeight;
  };

  const closeWidget = () => {
    var userAgent = navigator.userAgent.toLowerCase();
    var isAndroid = userAgent.indexOf("android") > -1;
    if (isAndroid) {
      window.KwikChat.onCloseClick();
    }
  };

  const handleImageLoad = () => {
    setLoadedImages((prev) => prev + 1);
  };

  chatMessages.forEach((message) => {
    if (message.content_type === "image") {
      messagesWithImages++;
    }
    messagesToRender.push(<ChatMessage message={message} socket={socket} userData={userData} key={message.id} onImageLoad={handleImageLoad} />);
  });

  const allImagesLoaded = loadedImages >= messagesWithImages;
  useEffect(() => {
    if (allImagesLoaded) {
      lastMessageRef.current?.scrollIntoView({ behavior: 'instant' });
    }
  }, [loadedImages]);

  useEffect(() => {
    if (allImagesLoaded) {
      lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatMessages]);

  return (
    <>
      <header className="chat__mainHeader">
        {userData.mid === '6322b067bfceeba8330fbc7e' || userData.mid === '648ae8f9c53f48ec4a0eb1fb' ? (
          <div className="profile-container">
            <img
              className="profile-picture"
              src="https://tellephant.s3.ap-south-1.amazonaws.com/durex-logo.svg"
              alt="Profile"
            />
            <h1 className="profile-name">{chatTitle}</h1>
          </div>
        ) : (
          <p className="chat__mainheader__name">{chatTitle}</p>
        )}
        {isMobile && showCloseButton === true &&
          <span onClick={closeWidget} style={{ cursor: 'pointer' }}>
            <FontAwesomeIcon icon={faXmark} />
          </span>
        }
        {showScrollButton && (
          <button onClick={scrollDown} className="chat__scroll-btn" aria-label="scroll down">
            <FontAwesomeIcon icon={faArrowDown} />
          </button>
        )}
      </header>
      <div className="message__container" id="msg__container" ref={scrollableDivRef}>
        {messagesToRender}
        <div ref={lastMessageRef} id="scrollRef" />
      </div>
    </>
  );
};

export default ChatBody;
