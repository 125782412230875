import { useEffect } from 'react';

function Theme({ theme }) {

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty('--text_color', theme.text_color);
    root.style.setProperty('--bg_color', theme.bg_color);
    root.style.setProperty('--link_color', theme.link_color);
    root.style.setProperty('--incoming_bubble_color', theme.incoming_bubble_color);
    root.style.setProperty('--incoming_text_color', theme.incoming_text_color);
    root.style.setProperty('--incoming_disabled_bubble_color', theme.incoming_disabled_bubble_color);
    root.style.setProperty('--incoming_disabled_text_color', theme.incoming_disabled_text_color);
    root.style.setProperty('--outgoing_bubble_color', theme.outgoing_bubble_color);
    root.style.setProperty('--outgoing_text_color', theme.outgoing_text_color);
    root.style.setProperty('--quickreplies_bg_color', theme.quickreplies_bg_color);
    root.style.setProperty('--quickreplies_text_color', theme.quickreplies_text_color);
    root.style.setProperty('--quickreplies_border_color', theme.quickreplies_border_color);
    root.style.setProperty('--quickreplies_disabled_bg_color', theme.quickreplies_disabled_bg_color);
    root.style.setProperty('--quickreplies_disabled_text_color', theme.quickreplies_disabled_text_color);
    root.style.setProperty('--quickreplies_disabled_border_color', theme.quickreplies_disabled_border_color);
    root.style.setProperty('--chatfooter_button_icon_color', theme.chatfooter_button_icon_color);
    root.style.setProperty('--chatfooter_button_color', theme.chatfooter_button_color);
    root.style.setProperty('--chatfooter_textarea_bg_color', theme.chatfooter_textarea_bg_color);
    root.style.setProperty('--chatfooter_textarea_color', theme.chatfooter_textarea_color);
    root.style.setProperty('--chatfooter_bg_color', theme.chatfooter_bg_color);
    root.style.setProperty('--header_bg_color', theme.header_bg_color);
    root.style.setProperty('--header_text_color', theme.header_text_color);
    root.style.setProperty('--scrolldown_bg_color', theme.scrolldown_bg_color);
    root.style.setProperty('--scrolldown_icon_color', theme.scrolldown_icon_color);
    root.style.setProperty('--context_bg_color', theme.context_bg_color);
    root.style.setProperty('--context_color', theme.context_color);
    root.style.setProperty('--highlight_bubble_bg', theme.highlight_bubble_bg);
    root.style.setProperty('--typing_icon_color', theme.typing_icon_color);
    root.style.setProperty('--chatalert_bg_color', theme.chatalert_bg_color);
    root.style.setProperty('--chatalert_text_color', theme.chatalert_text_color);
    root.style.setProperty('--chatclosed_bg_color', theme.chatclosed_bg_color);
    root.style.setProperty('--chatclosed_text_color', theme.chatclosed_text_color);
    root.style.setProperty('--chatclosed_link_bg_color', theme.chatclosed_link_bg_color);
    root.style.setProperty('--chatclosed_link_text_color', theme.chatclosed_link_text_color);
  }, [theme]);

  return null;
}

export default Theme;